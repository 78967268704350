import template from "images/template.png";
import { makeid } from "utils/helpers";

export const responseData = {
    created: 1700035737,
    data: [
      {
        revised_prompt:
          "An illustrated comic book cover featuring a muscular superhero of ambiguous gender and Hispanic descent. The superhero displays a broad smile as they wear a bright and colorful birthday party hat on their head. They stand in a heroic pose with their fists on hips, against a backdrop of stylized city buildings. The title of the comic book is bold and prominent, showing an energy-filled design to capture the celebratory theme.",
        url: template,
      },
      {
        revised_prompt:
          "A comic book cover showcasing a superhero character with an athletic build, dressed in a colorful costume adorned with imaginative insignia. The superhero is cheerfully celebrating a birthday, sporting a playful party hat on their head. The backdrop is filled with vibrant sparks of festivity, embodying the joyous ambiance. The title of the comic, in bold and engaging typeface, adds to the overall visual appeal of the cover.",
        url: template,
      },
      {
        revised_prompt:
          "Imagine a vibrant comic book cover featuring a muscular superhero. The protagonist is in their iconic costume, but with a colorful, pointed birthday party hat strapped onto their head. They are standing in a dynamic pose implying motion and action, perhaps in the middle of a battle. At their feet, there's a fluttered confetti mix of vibrant colors, while in the background, bursting fireworks celebrate the occasion. Their cape, a symbol of power and justice, is billowing in the wind. The title of the comic book proudly features at the top in bold, exciting fonts.",
        url: template,
      },
      {
        revised_prompt:
          "A detailed depiction of a comic book cover with an unexpected twist. The illustrated superhero, whose outfit includes a bold color scheme, towering stature, and signature emblem on his chest, has swapped his typical serious visage for a birthday party theme. In place of a conventional mask, he playfully sports a colorful pointed birthday party hat above his piercing eyes. His muscular form contrasts with the joviality of his attire, adding a humorous touch to the overall design of the comic book cover.",
        url: template,
      },
      // {
      //   revised_prompt:
      //     "An illustrated comic book cover featuring a muscular superhero of ambiguous gender and Hispanic descent. The superhero displays a broad smile as they wear a bright and colorful birthday party hat on their head. They stand in a heroic pose with their fists on hips, against a backdrop of stylized city buildings. The title of the comic book is bold and prominent, showing an energy-filled design to capture the celebratory theme.",
      //   url: "https://oaidalleapiprodscus.blob.core.windows.net/private/org-psZtuvvLUFhG5dcoZ5cYj0Kv/user-4Y4Q0WoCOP1mMEkFWaeCmldb/img-QPbhhG2v8H9jPLqKDV9i9UKo.png?st=2023-11-18T11%3A24%3A52Z&se=2023-11-18T13%3A24%3A52Z&sp=r&sv=2021-08-06&sr=b&rscd=inline&rsct=image/png&skoid=6aaadede-4fb3-4698-a8f6-684d7786b067&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2023-11-17T20%3A02%3A02Z&ske=2023-11-18T20%3A02%3A02Z&sks=b&skv=2021-08-06&sig=Yy9mDoEdOCcWIoy4AKOyQw7IKOXE9OVWG4WacUlWA9U%3D",
      // },
    ],
  },
  RESPONSE_SESSION_DATA = "cedar-session-response",
  testMode = false,
  PROJECT_SLUG = "cadar-image-generator-using-openai-dall-e-3--HWiUa",
  shippingMethods = [
    {
      id: makeid(12),
      title: "Royal Mail",
      duration: "1 to 3 days",
      amount: parseFloat(2.83).toFixed(2),
    },
    // {
    //   id: makeid(12),
    //   title: "UPS",
    //   duration: "1 to 3 days",
    //   amount: parseFloat(2.21).toFixed(2),
    // },
    {
      id: makeid(12),
      title: "Evri",
      duration: "1 to 3 days",
      amount: 4.29,
    },
    {
      id: makeid(12),
      title: "DHL Parcel UK",
      duration: "1 to 3 days",
      amount: 2.79,
    },
  ],
  apiHeaders = {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
  backendHost = "https://cader-stripe-intent-generator.onrender.com",
  taxes = 0.0,
  defaultPrice = (14.7).toFixed(2),
  fetchData = (url) => {
    return new Promise((resolve, reject) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => resolve(data))
        .catch((error) => reject(error));
    });
  };
