import { createSelector } from "reselect";
import { defaultPrice, taxes } from "utils/states/info";
const select = (state) => state.cart;

export const selectShipping = createSelector([select], (cart) => cart.shipping),
  selectCartData = createSelector([select], (cart) => cart.searchResults),
  selectSelected = createSelector([select], (cart) => cart.selected),
  selectShippingMethod = createSelector(
    [select],
    (cart) => cart.shippingMethod
  ),
  selectOrderSummary = createSelector([select], (cart) => cart.order),
  selecCartTotal = createSelector([select], (cart) => {
    const shippingMethod = cart.shippingMethod,
      shipping = shippingMethod == null ? 0 : shippingMethod.amount;
    return parseFloat(defaultPrice) + parseFloat(taxes) + parseFloat(shipping);
  }),
  selectAllData = createSelector([select], (cart) => ({
    shipping: cart.shipping,
    shippingMethod: cart.shippingMethod,
    selected: cart.selected ==null?cart.selected:{...cart.selected,b64_json:null},
  }));
// shippingMethod
