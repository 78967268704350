import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Landing from "./routes/landingpage.js";
import Loading from "./routes/loading.js";
import Selection from "./routes/posterselection.js";
import Display from "./routes/posterdisplay.js";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import AdminPanel from "routes/dashboard";
import Checkout from "routes/checkout";
import Shipping from "routes/checkout/shipping";
import PaymentPage from "routes/checkout/payment";
import OrderSummary from "routes/checkout/order-summary";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Landing />}
          />
          <Route
            path="/loading"
            element={<Loading />}
          />
          <Route
            path="/select-image"
            element={<Selection />}
          />

          <Route
            path="/display-image"
            element={<Display />}
          />
          <Route path="/checkout">
            <Route
              index
              element={<Checkout />}
            />
            <Route
              path="shipping"
              element={<Shipping />}
            />
            <Route
              path="payment"
              element={<PaymentPage />}
            />
            <Route
              path="order-completed"
              element={<OrderSummary />}
            />
          </Route>
          <Route
            path="/app/admin/*"
            element={<AdminPanel />}
          />
        </Routes>
      </BrowserRouter>
      <ToastContainer />
    </>
  );
}

export default App;
