import { Outlet, Link, useNavigate } from "react-router-dom";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import nextbtn from "../images/next button.png";
import backArrow from "../images/arrow-left.png";
import "components/Landing.css";
import "components/loading.css";
import "components/display.css";
import { useEffect, useState } from "react";
import Header from "components/header";
import { useDispatch, useSelector } from "react-redux";
import { selectCartData, selectSelected } from "store/cart/cart.selector";
import { storage } from "utils/firebase/firebase.utils";
import { generateUniqueId } from "utils/helpers";
import { setSelectedItem } from "store/cart/cart.action";
import { Pageloader } from "./loading";
import { defaultPrice } from "utils/states/info";

const Selection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [progresspercent, setProgresspercent] = useState(0);
  const searchResult = useSelector(selectCartData),
    selected = useSelector(selectSelected),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    proceed = () => {
      setIsLoading(true);
      const filename = `images/photo-${generateUniqueId()}.png`;
      fetch(
        selected.url == null
          ? `data:image/png;base64,${selected.b64_json}`
          : selected.url
      )
        .then((res) => {
          return res.blob();
        })
        .then((blob) => {
          //uploading blob to firebase storage
          // console.log(blob);
          const storageRef = ref(
            storage,
            `gs://cadar-project.appspot.com/${filename}`
          );
          const uploadTask = uploadBytesResumable(storageRef, blob);
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress = Math.round(
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100
              );
              setProgresspercent(progress);
            },
            (error) => {
              console.log(error);
              // setError({ error: true, message: error.message });
              setIsLoading(false);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                //   setImgUrl(downloadURL);
                dispatch(
                  setSelectedItem({ ...selected, savedUrl: downloadURL })
                );
                navigate("/checkout");
              });
            }
          );
        })
        .catch((error) => {
          console.error(error);
          setIsLoading(false);
        });
    };

  useEffect(() => {
    if (selected == null) {
      navigate("/select-image");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (isLoading) return <Pageloader />;
  return (
    <>
      <div className="container py-4 ">
        <div className="position-relative h-100">
          <Header />

          <div
            id="prompt-restart-cont"
            className="pt-1">
            <div className="prompt-back">
              <Link
                to="/select-image"
                className="restartbtn d-flex mr-5">
                <img
                  src={backArrow}
                  alt=""
                />
                <div>Back</div>
              </Link>
            </div>
            <div id="prompt-string">
              {searchResult == null ? (
                <center>
                  <div className="shimmer"></div>
                </center>
              ) : (
                <p
                  style={{
                    fontSize:
                      searchResult.search.split(" ").length > 14
                        ? "25px"
                        : "35px",
                  }}>
                  {searchResult.search}
                </p>
              )}
            </div>
          </div>

          <section>
            <div className="landingphrase select-one">
              <p>
                Click the arrow to let us know where to deliver this
                masterpiece.
              </p>
            </div>

            {/* images to be selected */}
            <div className="image-container row d-flex justify-content-center">
              {searchResult == null ? (
                <div
                  className="col-md-3 col-6"
                  style={{ backgroundColor: "#ddd" }}
                />
              ) : (
                <div className={`col-6 col-sm-3 gap-2`}>
                  <img
                    src={
                      selected.url == null
                        ? `data:image/png;base64,${selected.b64_json}`
                        : selected.url
                    }
                    alt="Placeholder"
                    width={"100%"}
                    height="100%"
                  />
                </div>
              )}
            </div>
          </section>

          <div className="price mb-4">£{defaultPrice}</div>

          <footer
            className="d-flex justify-content-between align-items-center mt-2"
            style={{ bottom: 0, right: 0, width: "100%" }}>
            <div className="selectText">
              Your selection will be in an A3 size format
            </div>

            <div className="nextbtn">
              <div className="confrimation">To the order summary page</div>
              <span
                onClick={proceed}
                role="button">
                <img
                  src={nextbtn}
                  alt=""
                />
              </span>
            </div>
          </footer>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Selection;
