import { createAction } from "../../utils/reducer/reducer.utils";
import { cartActions } from "./cart.slice";
import { CART_ACTION_TYPES } from "./cart.types";

export const setCartData = (data) =>
  createAction(CART_ACTION_TYPES.SET_SEARCH_RESULT, data);

export const setShipping = (data) =>
  createAction(CART_ACTION_TYPES.SET_SHIPPING, data);
export const uploadOrders = (data) =>
  createAction(CART_ACTION_TYPES.UPLOAD_ORDER, data);

export const setSelectedItem = (data) => cartActions.setSelected(data);
export const setShippingMethod = (data) => cartActions.setShippingMethod(data);
export const handleCheckoutCompleted = () => cartActions.finalizeOrder();
