import { Link } from "react-router-dom";

function Header() {
    return (<>
        <Link to={'/'} className="logo">
            <h1>Cader</h1>
        </Link>
    </>);
}
export const CheckoutHeader = ({ step = 1 }) => {
    const items = [
        'Cart', 'Information', 'Shipping', 'Payment'
    ]
    return <>
        <div className="px-2">
            <Link to={'/'} className="logo">
                <h3>Cader</h3>
            </Link>
        </div>
        <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
                {items.map((item, i) => (
                    <li className={`breadcrumb-item ${step === i ? 'active' : ''}`} key={i}>
                        {step !== i ? <a href="#!">{item}</a> : item}
                    </li>))}
            </ol>
        </nav></>
}

export default Header;