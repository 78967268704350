import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selecCartTotal, selectAllData, selectShipping } from "store/cart/cart.selector";
import Button from "components/button/button.component";
import { useNavigate } from "react-router-dom";
import { apiHeaders, backendHost } from "utils/states/info";
import { alertMessage } from "utils/helpers";
import { uploadOrders } from "store/cart/cart.action";

const ifValidCardElement = (
    card
) => card !== null;

const PaymentForm = () => {
    const [isProcessingPayment, setIsProcessingPayment] = useState(false),

        dispatch = useDispatch(),
        navigate = useNavigate()
    const stripe = useStripe();
    const elements = useElements();

    const shipping = useSelector(selectShipping),
        orderData = useSelector(selectAllData),
        total = useSelector(selecCartTotal)
    // console.log(orderData);
    const paymentHandler = async (e) => {
        e.preventDefault();

        if (!stripe || !elements) return;
        try {
            setIsProcessingPayment(true);
            // const url = "/.netlify/functions/create-payment-intent"
            const url = `${backendHost}/stripe/create-payment-intent`
            const response = await fetch(url, {
                method: "post",
                headers: apiHeaders,
                body: JSON.stringify({ amount: parseInt(parseFloat(total) * 100) }),
            }).then((res) => res.json());
            // console.log(response);

            const {
                paymentIntent: { client_secret },
            } = response;
            console.log(client_secret);
            const cardDetails = elements.getElement(CardElement);
            // if ((cardDetails) === null) return;
            if (!ifValidCardElement(cardDetails)) return;
            const paymentResult = await stripe.confirmCardPayment(client_secret, {
                payment_method: {
                    card: cardDetails,
                    billing_details: {
                        name: shipping ? `${shipping.firstname} ${shipping.lastname}` : "Ghest",
                    },
                },
            });
            setIsProcessingPayment(false);
            if (paymentResult.error) {
                alertMessage('error', paymentResult.error.message);
            } else {
                if (paymentResult.paymentIntent.status === "succeeded") {
                    alertMessage('success', "payment success");
                    dispatch(uploadOrders({ data: orderData, callback: () => navigate('/checkout/order-completed') }));
                    // dispatch(handleCheckoutCompleted())
                    // navigate('/checkout/order-completed')
                }
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsProcessingPayment(false);
        }
    };

    return (
        <div className="mt-3">
            <h5>Credit Card Payment</h5>
            <form onSubmit={paymentHandler}>
                <CardElement className="payment-card" options={{}} />


                <div className="d-flex justify-content-between align-items-center mt-5">
                    <button type="button" className="btn" onClick={() => navigate('/checkout/shipping')}>Go Back</button>
                    <Button
                        isLoading={isProcessingPayment}
                        style={{ width: '7rem' }}
                    >
                        {" "}
                        Pay now{" "}
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default PaymentForm;