import { Outlet, Link, useNavigate } from "react-router-dom";
import nextbtn from "../images/next button.png";
import rotate from "../images/rotate-left.png";
import "components/Landing.css";
import "components/loading.css";
import { useEffect, useState } from "react";
// import { RESPONSE_SESSION_DATA } from "utils/states/info";
import Header from "components/header";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectCartData } from "store/cart/cart.selector";
import { setCartData, setSelectedItem } from "store/cart/cart.action";

const Selection = () => {
  const [selected, setSelected] = useState(null),
    searchResult = useSelector(selectCartData),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    defaultArr = new Array(4).fill(0),
    proceed = () => {
      if (selected == null) {
        toast.warn("Please select an image...", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return false;
      }
      const sessionData = {
        ...searchResult,
        selected: searchResult.data[selected],
      };
      dispatch(setCartData(sessionData));
      dispatch(setSelectedItem(searchResult.data[selected]));
      // sessionStorage.setItem(
      //   RESPONSE_SESSION_DATA,
      //   JSON.stringify(sessionData)
      // );
      navigate("/display-image");
    };
  console.log(searchResult);
  useEffect(() => {
    if (searchResult == null) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectPoster = (index) => {
    setSelected(index);
  };
  return (
    <>
      <div className="container py-4 ">
        <div className="position-relative h-100">
          <Header />

          <div
            id="prompt-restart-cont"
            className="pt-1 row justify-content-end">
            <div
              id="prompt-string"
              className="col-sm-8 text-center"
              style={{ width: "100%" }}>
              {searchResult == null ? (
                <center>
                  <div className="shimmer"></div>
                </center>
              ) : (
                <p
                  style={{
                    fontSize:
                      searchResult.search.split(" ").length > 14
                        ? "25px"
                        : "35px",
                  }}>
                  {searchResult.search}
                </p>
              )}
            </div>
            <div className="col-sm-2 d-flex restartbtn-div">
              <Link
                to="/"
                className="restartbtn">
                <img
                  src={rotate}
                  alt=""
                />
                <div>Restart</div>
              </Link>
            </div>
          </div>

          <section>
            <div className="landingphrase select-one">
              <p>Select your favorite one!</p>
            </div>

            {/* images to be selected */}
            <div className="image-container row">
              {searchResult == null
                ? defaultArr.map((__, i) => (
                    <div key={i}>
                      <img
                        src={null}
                        alt=""
                      />
                    </div>
                  ))
                : searchResult.data.map((item, i) => (
                    <div
                      key={i}
                      className={`col-6 col-sm-3 gap-3 ${
                        selected === i ? "selectedImage" : ""
                      }`}
                      onClick={() => selectPoster(i)}>
                      <img
                        src={
                          item.url == null
                            ? `data:image/png;base64,${item.b64_json}`
                            : item.url
                        }
                        alt="Placeholder"
                        width={"100%"}
                        height="100%"
                      />
                    </div>
                  ))}
            </div>
          </section>

          <footer
            className="d-flex justify-content-between align-items-center px-5 mt-4"
            style={{ bottom: 0, right: 0, width: "100%" }}>
            <div className="selectText">
              Your selection will be in an A3 size format
            </div>

            <div className="nextbtn">
              <div className="confrimation">To the confirmation page</div>
              <span
                role="button"
                onClick={proceed}>
                <img
                  src={nextbtn}
                  alt=""
                />
              </span>
            </div>
          </footer>
        </div>
      </div>
      <Outlet />
    </>
  );
};

export default Selection;
