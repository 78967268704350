
import loader from "images/ZZ5H.gif";
import { useSelector } from "react-redux";
import { selecCartTotal, selectSelected, selectShippingMethod } from "store/cart/cart.selector";
import { defaultPrice } from "utils/states/info";

function Cart({ order = null }) {
    const
        _selected = useSelector(selectSelected),
        _shippingMethod = useSelector(selectShippingMethod),
        _total = useSelector(selecCartTotal);

    const selected = order == null ? _selected : order.selected,
        shippingMethod = order == null ? _shippingMethod : order.shippingMethod,
        total = order == null ? _total : order.total

    return (
        <div className="container">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex gap-2 align-items-center">
                    {selected == null ? <div className="d-flex justify-content-center align-items-center" style={{
                        width: 50,
                        aspectRatio: '4/7',
                        backgroundColor: '#c1c1c1'
                    }}>
                        <img
                            src={loader}
                            alt="loader"
                            style={{
                                width: 16,
                            }}
                        />
                    </div> : <img src={selected.savedUrl}
                        alt="Placeholder"
                        width={"50"}
                        style={{
                            aspectRatio: '4/7'
                        }} />}
                    <div className="d-flex flex-column">
                        <span><b>Cader Poster</b></span>
                        <span className="text-muted">A3 Poster</span>
                    </div>
                </div>
                <span className="sub-total">&#163;{parseFloat(defaultPrice).toFixed(2)}</span>
            </div>
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="sub-total text-weight-bold">Subtotal</span>
                <span className="sub-total">&#163;{parseFloat(defaultPrice).toFixed(2)}</span>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="sub-total text-weight-bold">Shipping</span>
                <span className={shippingMethod === null ? 'text-muted' : 'sub-total'}>{shippingMethod === null ? "Calculated at next step " : <>&#163;{parseFloat(shippingMethod.amount).toFixed(2)}</>}</span>
            </div>
            {/* <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="sub-total text-weight-bold">Taxes</span>
                <span className="sub-total">&#163;{taxes}</span>
            </div> */}
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-2">
                <span className="total text-weight-bold">Total</span>
                <span className="total">&#163;{parseFloat(total).toFixed(2)}</span>
            </div>
        </div>
    );
}

export default Cart;