// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getAuth,
  signInWithRedirect,
  signInWithPopup,
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  doc,
  getDoc,
  setDoc,
  collection,
  writeBatch,
  query,
  getDocs,
  updateDoc,
  where,
  deleteDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { longEnUSFormatter } from "../helpers";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCTuKAKjIY5R8v2t_f9ucCcS9XxKYI6OYI",
  authDomain: "cadar-project.firebaseapp.com",
  projectId: "cadar-project",
  storageBucket: "cadar-project.appspot.com",
  messagingSenderId: "875770558816",
  appId: "1:875770558816:web:dcae81058689af94e10605"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth();
export const storage = getStorage(firebaseApp);
//-------------- using google sign in-------------
const googleProvider = new GoogleAuthProvider();

googleProvider.setCustomParameters({
  prompt: "select_account",
});

export const signInWithGooglePopup = () =>
  signInWithPopup(auth, googleProvider);
export const signInWithGoogleRedirect = () =>
  signInWithRedirect(auth, googleProvider);
//-------------- using google sign in-------------

export const db = getFirestore();

export const addCollectionAndDocuments = async (
  collectionKey,
  objectsToAdd
) => {
  const collectionRef = collection(db, collectionKey);
  const batch = writeBatch(db);

  objectsToAdd.forEach((object) => {
    const docRef = doc(collectionRef, object.title.toLowerCase());
    batch.set(docRef, object);
  });
  await batch.commit();
  // console.log("done");
};

export const getCategoriesAndDocuments = async () => {
  const collectionRef = collection(db, "categories");
  const q = query(collectionRef);

  const querySnapShot = await getDocs(q);
  const categoryMap = querySnapShot.docs.reduce((acc, docSnapshot) => {
    const { title, items } = docSnapshot.data();
    acc[title.toLowerCase()] = items;
    return acc;
  }, {});

  return categoryMap;
};
export const userSignUp = async (userData, email, password) => {
  if (!email || !password) return;
  const {
    approvedVendor,
    fName,
    firstname,
    lastname,
    isManager,
    number,
    userName,
    userType,
  } = userData;

  const createdAt = longEnUSFormatter.format(new Date());
  const defaultImg =
    "https://firebasestorage.googleapis.com/v0/b/myspotmart-db-1.appspot.com/o/users%2Fkindpng_785827.png?alt=media&token=a732927b-bfa9-4699-95ba-e71a1c5b6839";
  const newUser = {
    fName,
    firstname,
    lastname,
    userName,
    email,
    number,
    userType,
    image: defaultImg,
    approvedVendor,
    isManager,
    createdAt,
  };
  return await createUserWithEmailAndPassword(auth, email, password).then(
    (credential) => {
      if (credential && credential.user) {
        collection("users")
          .doc(credential.user.uid)
          .set({
            ...newUser,
            managerId: credential.user.uid,
            userId: credential.user.uid,
            token: credential.user.accessToken.substring(0, 28),
          });
      }
    }
  );
};
//
export const addCtgy = async (ctgyData, categoryType) => {
  if (!ctgyData) return;
  const collectionRef = collection(db, categoryType);

  // console.log(collectionRef);
  const q = query(collectionRef, where("slug", "==", ctgyData.slug));
  const querySnapshot = await getDocs(q);
  // console.log("querySnapshot.empty: ", );
  if (querySnapshot.empty) {
    const createdAt = longEnUSFormatter.format(new Date());
    const newData = {
      ...ctgyData,
      createdAt,
    };
    try {
      const ctgyDocRef = doc(collectionRef);
      await setDoc(ctgyDocRef, newData);
      // console.log("created");
    } catch (error) {
      throw new Error("error creating category", error.message);
    }
  } else {
    throw new Error("The category already exist");
  }
};
export const getCtgys = async (categoryType) => {
  if (!categoryType) return;
  try {
    const collectionRef = collection(db, categoryType);

    // console.log(collectionRef);
    const q = query(collectionRef);
    const querySnapshot = await getDocs(q);
    // console.log("querySnapshot: ", querySnapshot);
    const categories = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      const id = doc.id;
      // console.log("id", doc.id);
      return { id, ...data };
    });
    return categories;
  } catch (error) {
    throw new Error(error);
  }
};
export const createUserDocumentFromAuth = async (
  userAuth
  // additionalInformation = {}
) => {
  if (!userAuth) return;
  const userDocRef = doc(db, "users", userAuth.uid);

  // console.log(userDocRef);

  const userSnapshot = await getDoc(userDocRef);
  // console.log(userSnapshot);

  //   if user data does not exist
  if (!userSnapshot.exists()) {
    const {
      approvedVendor,
      fName,
      firstname,
      lastname,
      isManager,
      number,
      userName,
      userType,
      email,
      uid,
      accessToken,
    } = userAuth;

    const createdAt = longEnUSFormatter.format(new Date());
    const defaultImg =
      "https://firebasestorage.googleapis.com/v0/b/myspotmart-db-1.appspot.com/o/users%2Fkindpng_785827.png?alt=media&token=a732927b-bfa9-4699-95ba-e71a1c5b6839";
    const newUser = {
      fName,
      firstname,
      lastname,
      userName,
      email,
      number,
      userType,
      image: defaultImg,
      approvedVendor,
      managerId: uid,
      userId: uid,
      isManager,
      token: accessToken.substring(0, 28),
      createdAt,
    };
    try {
      await setDoc(userDocRef, newUser);
      // console.log("created");
    } catch (error) {
      auth.currentUser.delete();
      throw new Error("error creating the user", error.message);
    }
  }
  return userDocRef;
};

//-------------- email and password sign in/signup -------------

export const createAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;

  return await createUserWithEmailAndPassword(auth, email, password);
};
export const signInAuthUserWithEmailAndPassword = async (email, password) => {
  if (!email || !password) return;
  return await signInWithEmailAndPassword(auth, email, password);
};

export const signOutUser = async () => await signOut(auth);

export const onAuthStateChangedListener = (callback) =>
  onAuthStateChanged(auth, callback);

export const getUsers = async (user) => {
  const collectionRef = collection(db, "users");
  const docRef = doc(collectionRef, "user.uid");
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    // console.log(user.email);
    const q = query(collection(db, "users"), where("email", "==", user.email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        const id = doc.id;
        // console.log("id", doc.id);
        return { id, ...data };
      });
      // console.log("userData[0]: ", userData[0]);
      return userData[0];
    } else {
      throw new Error("No user associated with this account");
    }
  }
};

export const getStore = async (uid, type) => {
  const userType =
    type === "Services"
      ? "services"
      : type === "Agent"
      ? "house_agents"
      : type === "Food Vendor"
      ? "food_vendor"
      : "goods_store";
  const collectionRef = collection(db, userType);
  const docRef = doc(collectionRef, uid);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    await setDoc(docRef, {
      brand_name: "",
      brand_email_1: "",
      brand_email_2: "",
      brand_tel_1: "",
      brand_tel_2: "",
      brand_address_1: "",
      brand_address_2: "",
      brand_description: "",
      brand_logo:
        "https://firebasestorage.googleapis.com/v0/b/myspotmart-db-1.appspot.com/o/brand-img%2Ficon_default.png?alt=media&token=ddfd4f82-0a18-455c-8edf-29123ba70880",
      brand_banner:
        "https://firebasestorage.googleapis.com/v0/b/myspotmart-db-1.appspot.com/o/brand-img%2Fdefault-banner.jpg?alt=media&token=5ff2a88b-0a52-4494-89f0-270b144a2ea7",
      brand_bank_name: "",
      brand_bank_username: "",
      brand_bank_number: "",
      uid: uid,
    });
    return getStore(uid, type);
  }
};

export const updateStoreImage = async (uid, type, field, file_name) => {
  // console.log(uid, type, field, file_name);
  const userType =
    type === "Services"
      ? "services"
      : type === "Agent"
      ? "house_agents"
      : type === "Food Vendor"
      ? "food_vendor"
      : "goods_store";
  const collectionRef = collection(db, userType);
  const docRef = doc(collectionRef, uid);
  // const docSnap = await getDoc(docRef);

  if (field === "brand_banner") {
    await updateDoc(docRef, {
      brand_banner: file_name,
    });
  } else {
    await updateDoc(docRef, {
      brand_logo: file_name,
    });
  }
};
export const updateCatgory = async (categoryData, ctgyType) => {
  // console.log({ categoryData, ctgyType });

  const collectionRef = collection(db, ctgyType);
  const docRef = doc(collectionRef, categoryData.id);
  // const docSnap = await getDoc(docRef);
  await updateDoc(docRef, categoryData);
};
export const updateStore = async (uid, type, dataToUpload) => {
  const userType =
    type === "Services"
      ? "services"
      : type === "Agent"
      ? "house_agents"
      : type === "Food Vendor"
      ? "food_vendor"
      : "goods_store";
  const collectionRef = collection(db, userType);
  const docRef = doc(collectionRef, uid);
  await updateDoc(docRef, dataToUpload);
};

export const addProduct = async (formdata) => {
  if (!formdata) return;
  const collectionRef = collection(db, "products");
  const createdAt = longEnUSFormatter.format(new Date());
  const newData = {
    ...formdata,
    createdAt,
  };
  try {
    const productsRef = doc(collectionRef);
    await setDoc(productsRef, newData);
    // console.log("created");
  } catch (error) {
    throw new Error("error creating product", error.message);
  }
};
export const addServiceImage = async (formdata) => {
  if (!formdata) return;
  const createdAt = longEnUSFormatter.format(new Date());
  const newData = {
    ...formdata,
    createdAt,
  };
  try {
    const galleryRef = doc(db, "gallery", formdata.imageId);
    await setDoc(galleryRef, newData);
    // console.log("created");
  } catch (error) {
    throw new Error("error creating gallery", error.message);
  }
};
export const getGallery = async () => {
  const collectionRef = collection(db, "gallery");
  const q = query(collectionRef);

  const querySnapShot = await getDocs(q);
  const galleryMap = querySnapShot.docs.map((doc) => {
    const prod = doc.data();
    const id = doc.id;
    // console.log("id", doc.id);
    return { ...prod, id };
  });
  return galleryMap;
};
export const updateProduct = async (formdata) => {
  if (!formdata) return;
  const collectionRef = collection(db, "products");
  const updatedAt = longEnUSFormatter.format(new Date());
  const newData = {
    ...formdata,
    updatedAt,
  };
  try {
    const docRef = doc(collectionRef, formdata.id);
    // const docSnap = await getDoc(docRef);
    await updateDoc(docRef, newData);
  } catch (error) {
    throw new Error("error updating product", error.message);
  }
};

export const getProduct = async () => {
  const collectionRef = collection(db, "products");
  const q = query(collectionRef);

  const querySnapShot = await getDocs(q);
  const categories = await getCtgys("goods_category");
  const productMap = querySnapShot.docs.map((doc) => {
    const prod = doc.data();
    const id = doc.id;
    // console.log("id", doc.id);
    const ctgy = categories.filter((item) => item.id === prod.category)[0];
    return { ...prod, prodCtgy: ctgy == null ? "" : ctgy.name, prodId: id, id };
  });
  return productMap;
};

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubcribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubcribe();
        resolve(userAuth);
      },
      reject
    );
  });
};

export const deleteProductFromDB = async (prodId) => {
  try {
    const docRef = doc(db, "products", prodId);
    await deleteDoc(docRef);
  } catch (error) {
    console.log(error);
  }
};

export const addOrderDetails = async (formdata) => {
  if (!formdata) return;
  const collectionRef = collection(db, "orders");
  const createdAt = longEnUSFormatter.format(new Date());
  const newData = {
    ...formdata,
    createdAt,
  };
  console.log('newData: ',newData);
  try {
    const ordersRef = doc(collectionRef);
    await setDoc(ordersRef, newData);
    // console.log("created");
  } catch (error) {
    throw new Error("error creating order", error.message);
  }
};

export const getAllOrders = async () => {
  const collectionRef = collection(db, "orders");
  const q = query(collectionRef);

  const querySnapShot = await getDocs(q);
  const ordersMap = querySnapShot.docs.map((doc) => {
    const prod = doc.data();
    const id = doc.id;
    // console.log("id", doc.id);
    return { ...prod, id };
  });
  return ordersMap;
};
