import Cart from "components/checkout/cart.component";
import ShippingForm from "components/checkout/ship-form.component";
import { CheckoutHeader } from "components/header";
import { useEffect, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pageloader } from "routes/loading";
import { selectCartData, selectShipping } from "store/cart/cart.selector";

function Shipping() {
    const
        searchResult = useSelector(selectCartData),
        shipping = useSelector(selectShipping),
        navigate = useNavigate()


    useEffect(() => {
        if (searchResult == null) {
            navigate("/");
        }
        if (shipping == null) {
            navigate("/checkout");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (searchResult == null || shipping == null) return <Pageloader />
    return (
        <div className="row m-0 overflow-hidden w-100" id="checkout">
            <div className="col-md-7  py-5">
                <div className="px-5 ">
                    <CheckoutHeader step={2} />
                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-3">
                                <span className="text-sm text-mute">Contact:</span>
                                <span className="text-sm">{shipping.email}</span>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-2">
                                <span className="text-sm text-mute">Ship to:</span>
                                <span className="text-sm">{shipping.apartment} {shipping.address}, {shipping.city} {shipping.province}, {shipping.country}</span>
                            </div>
                        </li>
                    </ul>

                    <ShippingForm />
                </div>
            </div>
            <div className="col-md-5 checkout-right w-100 pt-5" >
                <div className="px-5 ">
                    <Cart />
                </div>
            </div>
        </div>);
}

export default Shipping;