import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setShippingMethod } from "store/cart/cart.action";
import { selectShippingMethod } from "store/cart/cart.selector";
import { alertMessage } from "utils/helpers";
import { shippingMethods } from "utils/states/info";

function ShippingForm() {
    const shippingMethod = useSelector(selectShippingMethod),
        dispatch = useDispatch(),
        navigate = useNavigate(),
        handleClick = (item) => {
            dispatch(setShippingMethod(item))
        }, proceed = () => {
            if (shippingMethod == null) {
                alertMessage('warning', 'Please select shipping method');
                return
            }
            navigate('/checkout/payment')
        }

    return (
        <div className="mt-3">
            <h5>Shipping Method</h5>
            <ul className="list-group">
                {shippingMethods.map(item => <li className="list-group-item" key={item.id}>
                    <label className="d-flex justify-content-between align-items-center gap-3 clickable"
                        onClick={() => handleClick(item)}
                        htmlFor={`input-${item.id}`}>
                        <div className="d-flex gap-2" >
                            <input
                                type="radio"
                                name="exampleRadios"
                                id={`input-${item.id}`}
                                value={item.id}
                                checked={shippingMethod == null ? false : shippingMethod.id === item.id}
                                style={{
                                    width: 20
                                }}
                            />
                            <div className="d-flex flex-column" >
                                <span className="text-sm font-weight-700">{item.title}</span>
                                <span className="text-sm text-mute">{item.duration}</span>
                            </div>
                        </div>

                        <span className="text-sm">&#163;{parseFloat(item.amount).toFixed(2)}</span>
                    </label>
                </li>)}
            </ul>
            <div className="d-flex justify-content-between align-items-center mt-5">
                <button type="button" className="btn" onClick={() => navigate('/checkout')}>Go Back</button>
                <button type="button" className="btn btn-primary" onClick={proceed}>Continue to Payment</button>
            </div>
        </div>);
}

export default ShippingForm;