import { createSlice } from "@reduxjs/toolkit";
import { defaultPrice, taxes } from "utils/states/info";
// import { responseData } from "utils/states/info";

export const defaultCartField = null;
const cartSlice = createSlice({
  name: "cart",
  initialState: {
    shipping: null,
    searchResults: defaultCartField,
    selected: null,
    shippingMethod: null,
    order: null,
  },
  reducers: {
    setResult(state, action) {
      state.searchResults = action.payload;
    },
    setShipping(state, action) {
      state.shipping = action.payload;
    },
    setSelected(state, action) {
      state.selected = action.payload;
    },
    setShippingMethod(state, action) {
      state.shippingMethod = action.payload;
    },
    finalizeOrder(state, action) {
      const { shipping, shippingMethod, searchResults, selected } = state;
      if (shippingMethod != null) {
        const shippingMethod = state.shippingMethod,
          shippingAmount = shippingMethod == null ? 0 : shippingMethod.amount,
          total =
            parseFloat(defaultPrice) +
            parseFloat(taxes) +
            parseFloat(shippingAmount);
        state.order = {
          shipping,
          shippingMethod,
          searchResults,
          selected,
          total,
        };
        state.shippingMethod = null;
        state.shipping = null;
        state.searchResults = null;
        state.selected = null;
      }
    },
  },
});

export const cartActions = cartSlice.actions;
export default cartSlice;
