import loader from "images/ZZ5H.gif";

function OrderLoader() {
    return (
        <div className="bg-white position-fixed d-flex justify-content-center align-items-center"
            style={{ inset: 0 }} >
            <div className="d-flex flex-column justify-content-center align-items-center p-5" role="status">
                <img
                    src={loader}
                    alt="loader"
                    style={{
                        width: 20,
                    }}
                />
                <span>Your order's being processed.</span>
                <p>If you're not automatically redirected, <span className="text-primary">refresh this page</span></p>
            </div>

        </div>
    );
}

export default OrderLoader;