import React, { Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import { Pageloader } from "routes/loading";
import 'components/dashboard/dashboard.scss'

import { checkUserSession } from "store/user/user.actions";
const Authentication = lazy(() => import("./auth"));
const Dashboard = lazy(() => import("./home"));

function AdminPanel() {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(checkUserSession());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <main id="dashboard">
            <Suspense fallback={<Pageloader />}>
                <Routes>
                    <Route
                        path="/auth"
                        element={<Authentication />}>
                    </Route>
                    <Route path="/dashboard" element={<Dashboard />} />
                </Routes>
            </Suspense>
        </main>);
}

export default AdminPanel;