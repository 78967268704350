import { combineReducers } from "@reduxjs/toolkit";

import themeSlice from "./theme/theme-slice";
import userSlice from "./user/user.slice";
import cartSlice from "./cart/cart.slice";

export const rootReducer = combineReducers({
  theme: themeSlice.reducer,
  user: userSlice.reducer,
  cart: cartSlice.reducer,
});
