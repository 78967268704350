import Cart from "components/checkout/cart.component";
import { CheckoutHeader } from "components/header";
import { useEffect, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pageloader } from "routes/loading";
import { selectOrderSummary } from "store/cart/cart.selector";

const OrderSummary = () => {
    const order = useSelector(selectOrderSummary),
        navigate = useNavigate()


    useEffect(() => {
        if (order == null) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (order == null) return <Pageloader />
    const {
        shipping,
        shippingMethod } = order
    return (
        <div className="row m-0 overflow-hidden w-100" id="checkout">
            <div className="col-md-7  py-5">
                <div className="px-5 ">
                    <CheckoutHeader step={3} />
                    <div className="card" >
                        <iframe
                            title="address-map"
                            src={`https://maps.google.com/maps?q=${shipping.address}, ${shipping.city} ${shipping.province}, ${shipping.country}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
                            allowFullScreen=""
                        />
                        <div className="card-body">
                            <span className="card-title">Your order is confirmed</span>
                            <p className="card-text">We've accepted your order, and we're getting it ready.</p>
                        </div>
                    </div>
                    <div className="card mt-3">
                        <div className="card-body" style={{ padding: '0 1.0rem' }}>
                            <span className="text-sm">Customer Information</span>
                            <div className="row mt-1" >
                                <div className="col-6 d-flex flex-column">
                                    <span className="text-sm">Customer Information</span>
                                    <span className="text-xs">{shipping.email}</span>
                                    <span className="text-xs">{shipping.apartment} {shipping.address}</span>
                                    <span className="text-xs">{shipping.city}, {shipping.province}</span>
                                    <span className="text-xs">{shipping.country}</span>
                                </div>
                                <div className="col-6 px-2  d-flex flex-column">
                                    <span className="text-sm">Shipping Method</span>
                                    <span className="text-xs">{shippingMethod.title}</span>
                                    <span className="text-xs">&#163;{parseFloat(shippingMethod.amount).toFixed(2)}</span>
                                    <span className="text-xs">{shippingMethod.duration}</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    {/* <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-3">
                                <span className="text-sm text-mute">Contact:</span>
                                <span className="text-sm">{shipping.email}</span>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-2">
                                <span className="text-sm text-mute">Ship to:</span>
                                <span className="text-sm">{shipping.apartment} {shipping.address}, {shipping.city} {shipping.province}, {shipping.country}</span>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-2">
                                <span className="text-sm text-mute">Method:</span>
                                <span className="text-sm d-flex flex-column">
                                    <span>{shippingMethod.title} - &#163;{shippingMethod.amount}</span>
                                    <span className="text-muted">{shippingMethod.duration}</span>
                                </span>
                            </div>
                        </li>
                    </ul> */}

                    {/* <PaymentForm /> */}
                </div>
            </div>
            <div className="col-md-5 checkout-right w-100 pt-5" >
                <div className="px-5 ">
                    <Cart order={order} />
                </div>
            </div>
        </div>);
}

export default OrderSummary 