import Cart from "components/checkout/cart.component";
import OrderLoader from "components/checkout/loading.component";
import PaymentForm from "components/checkout/payment-form.component";
import { CheckoutHeader } from "components/header";
import { useEffect, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Pageloader } from "routes/loading";
import { selectCartData, selectShipping, selectShippingMethod } from "store/cart/cart.selector";
import { selectIsLoading } from "store/user/user.selector";

function PaymentPage() {
    const
        searchResult = useSelector(selectCartData),
        shipping = useSelector(selectShipping),
        shippingMethod = useSelector(selectShippingMethod),
        isLoading = useSelector(selectIsLoading),
        navigate = useNavigate()


    useEffect(() => {
        if (searchResult == null) {
            navigate("/");
        }
        if (shipping == null) {
            navigate("/checkout");
        }
        if (shippingMethod == null) {
            navigate("/checkout/shipping");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (searchResult == null || shipping == null || shippingMethod == null) return <Pageloader />

    if (isLoading) return <OrderLoader />
    return (
        <div className="row m-0 overflow-hidden w-100" id="checkout">
            <div className="col-md-7  py-5">
                <div className="px-5 ">
                    <CheckoutHeader step={3} />
                    <ul className="list-group">
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-3">
                                <span className="text-sm text-mute">Contact:</span>
                                <span className="text-sm">{shipping.email}</span>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-2">
                                <span className="text-sm text-mute">Ship to:</span>
                                <span className="text-sm">{shipping.apartment} {shipping.address}, {shipping.city} {shipping.province}, {shipping.country}</span>
                            </div>
                        </li>
                        <li className="list-group-item">
                            <div className="d-flex align-items-center gap-2">
                                <span className="text-sm text-mute">Method:</span>
                                <span className="text-sm d-flex flex-column">
                                    <span>{shippingMethod.title} - &#163;{shippingMethod.amount}</span>
                                    <span className="text-muted">{shippingMethod.duration}</span>
                                </span>
                            </div>
                        </li>
                    </ul>

                    <PaymentForm />
                </div>
            </div>
            <div className="col-md-5 checkout-right w-100 pt-5" >
                <div className="px-5 ">
                    <Cart />
                </div>
            </div>
        </div>);
}

export default PaymentPage;