import React, { useEffect, useState } from "react";
import loader from "images/ZZ5H.gif";
import "components/Landing.css";
import "components/loading.css";
import Header from "components/header";

const Preloader = ({
  searchValue = "A bear playing the bass guitar with red fur",
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const messageArr = [
    "Okay got it!",
    "Loading the options",
    "Detailing the options",
    "Taking a bite of a chocolate chip cookie",
    "Cleaning off the crumbs",
    "Ready to present!",
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messageArr.length);
    }, 2000);

    setTimeout(() => {
      clearInterval(intervalId);
    }, 2000 * messageArr.length);

    return () => clearInterval(intervalId);
  }, [messageArr.length]);

  return (
    <div
      className="testbg animate__animated animate__fadeIn"
      id="preloader">
      <div className="squareloaders">
        <div id="one"></div>
        <div id="two"></div>
        <div id="three"></div>
        <div id="four"></div>
        <div id="five"></div>
      </div>
      <div className="container ">
        <div className="container py-4">
          <Header />

          <center>
            <div
              id="prompt-string"
              className="pt-4 loading-prompt">
              <p
                style={{
                  fontSize:
                    searchValue.split(" ").length > 14 ? "25px" : "35px",
                }}>
                {searchValue}
              </p>
            </div>
          </center>
          <center>
            <img
              src={loader}
              alt="loader"
              className="mt-5"
              style={{
                width: "2rem",
              }}
            />
          </center>

          <div
            style={{ paddingTop: "12%" }}
            className="landingphrase">
            {messageArr.map((value, i) => (
              <p
                id="fade-in"
                key={i}
                style={currentIndex === i ? {} : { display: "none" }}
                className="animate__animated animate__fadeIn">
                {value}
              </p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export const Pageloader = () => {
  return (
    <div
      className="testbg animate__animated animate__fadeIn"
      id="preloader">
      <div className="squareloaders">
        <div id="one"></div>
        <div id="two"></div>
        <div id="three"></div>
        <div id="four"></div>
        <div id="five"></div>
      </div>
      <div className="container ">
        <div className="container py-4">
          <center>
            <div
              id="prompt-string"
              className="pt-4 loading-prompt">
              <p
                style={{
                  fontSize: "35px",
                }}>
                Loading...
              </p>
            </div>
          </center>
          <center>
            <img
              src={loader}
              alt="loader"
              className="mt-5"
              style={{
                width: "2rem",
              }}
            />
          </center>

          <div
            style={{ paddingTop: "12%" }}
            className="landingphrase"></div>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
