import { all, call, put, takeLatest } from "redux-saga/effects";
import { cartActions } from "./cart.slice";
import { CART_ACTION_TYPES } from "./cart.types";
import { setIsLoading } from "store/user/user.actions";
import { addOrderDetails } from "utils/firebase/firebase.utils";
import { handleCheckoutCompleted } from "./cart.action";

export function* setSearchResult({ payload }) {
  yield put(cartActions.setResult(payload));
}
export function* setShippingDetails({ payload }) {
  yield put(cartActions.setShipping(payload));
}

export function* addOrderToDB({ payload }) {
  // console.log(payload);
  try {
    yield put(setIsLoading(true));
    yield call(addOrderDetails, payload.data);
    yield put(handleCheckoutCompleted());
    payload.callback();
    yield put(setIsLoading(false));
  } catch (error) {
    yield put(setIsLoading(false));
    console.log(error);
  }
}

// --------------------------- event listeners ------------------------------------//
export function* onSetSearchResult() {
  yield takeLatest(CART_ACTION_TYPES.SET_SEARCH_RESULT, setSearchResult);
}
export function* onSetShippingDetails() {
  yield takeLatest(CART_ACTION_TYPES.SET_SHIPPING, setShippingDetails);
}
export function* onAddOrder() {
  yield takeLatest(CART_ACTION_TYPES.UPLOAD_ORDER, addOrderToDB);
}

// --------------------------- event listeners ------------------------------------//
export function* cartSaga() {
  yield all([
    call(onSetSearchResult),
    call(onSetShippingDetails),
    call(onAddOrder),
  ]);
}
