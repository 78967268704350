import React, { useState, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import bg1 from "images/background1.png";
import bg2 from "images/background2.png";
import bg3 from "images/background3.png";
import tiktok from "images/Tik Tok.png";
import instagram from "images/Instagram.png";
import "components/Landing.css";
import OpenAI from "openai";
import Preloader from "./loading";
import {
  RESPONSE_SESSION_DATA,
  responseData,
  testMode,
} from "utils/states/info";
import Header from "components/header";
import { useDispatch } from "react-redux";
import { setCartData } from "store/cart/cart.action";

const images = [bg1, bg2, bg3];

const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true,
});
const Landing = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const messageArr = [
    "An oil painting of a humanoid robot playing chess",
    "3D render of a cute fish in an aquarium on a dark blue background, digital art",
    "A cartoon of a monkey in space",
    "High quality photo of a monkey astronaut",
    "An Andy Warhol style painting of a french bulldog wearing sunglasses",
    "A Formula 1 car driving on a neon road",
  ];

  const [formData, setFormData] = useState({ search: "" }),
    [loading, setLoading] = useState(false),
    { search } = formData;
  const [currentImage, setCurrentImage] = useState(0),
    navigate = useNavigate(),
    dispatch = useDispatch(),
    handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((state) => ({ [name]: value }));
    },
    imageGenerator = () => {
      return new Promise((resolve, reject) => {
        openai.images
          .generate({
            model: "dall-e-3",
            prompt: search,
            n: 1,
            quality: "hd",
            size: "1024x1792",
            response_format: "b64_json",
          })
          .then((data) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    testApiFetch = () => {
      // const urls = [
      //   "https://www.cryptohopper.com/rest/api/v2/news/list?page=1&limit=12",
      //   "https://www.cryptohopper.com/rest/api/v2/news/list?page=1&limit=12",
      //   "https://www.cryptohopper.com/rest/api/v2/news/list?page=1&limit=12",
      //   "https://www.cryptohopper.com/rest/api/v2/news/list?page=1&limit=12",
      // ];

      // const promises = urls.map((url) => fetchData(url));
      const sessionData = { search, data: responseData.data };
      dispatch(setCartData(sessionData));
      sessionStorage.setItem(
        RESPONSE_SESSION_DATA,
        JSON.stringify(sessionData)
      );
      navigate("/select-image");
    },
    imageGeneratorPromise = () => {
      const urls = new Array(4).fill(0);

      const promises = urls.map((_) => imageGenerator());

      Promise.all(promises)
        .then((results) => {
          // console.log("results: ", results);
          const data = results.map((item) => item.data).flat();

          const sessionData = { search, data: data };
          dispatch(setCartData(sessionData));
          // sessionStorage.setItem(
          //   RESPONSE_SESSION_DATA,
          //   JSON.stringify(sessionData)
          // );
          navigate("/select-image");
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
    handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (testMode) {
        testApiFetch();
      } else {
        imageGeneratorPromise();
      }
    };

  // alternating background image
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  // change of prompt example
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % messageArr.length);
    }, 3000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {loading && <Preloader searchValue={search} />}

      <div className="container py-4">
        <div
          className="changeimage"
          style={{ zIndex: -1 }}>
          {images.map((image, i) => (
            <img
              key={i}
              src={image}
              alt=""
              style={currentImage === i ? {} : { display: "none" }}
              className="animate__animated animate__fadeIn"
            />
          ))}
        </div>
        <div className="container">
          <Header />

          <div className="">
            <div className="landingtext">White walls are boring</div>
            <div className="landingtext">
              Customise your space how you want it,
            </div>
            <div className="landingtext">
              it is time to really make your space yours
            </div>

            <p className="landingphrase pt-4">
              We create and deliver unique A3 posters straight from your
              <br /> imagination, the options are infinite{" "}
            </p>
          </div>

          <div className="inputsection">
            <p>What kind of poster you want, we’ll show you some examples!</p>

            <form
              onSubmit={handleSubmit}
              className="query-form">
              <div className="position-relative">
                <input
                  type="text"
                  name="search"
                  value={search}
                  onChange={handleChange}
                  placeholder="A bear playing the bass guitar with red fur"
                  required
                />
                <button
                  type="submit"
                  className="btn position-absolute rounded-circle"
                  id="searchbtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    fill="currentColor"
                    height="12px"
                    width="12px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 330 330"
                    xmlSpace="preserve">
                    <path
                      id="XMLID_27_"
                      d="M15,180h263.787l-49.394,49.394c-5.858,5.857-5.858,15.355,0,21.213C232.322,253.535,236.161,255,240,255  s7.678-1.465,10.606-4.394l75-75c5.858-5.857,5.858-15.355,0-21.213l-75-75c-5.857-5.857-15.355-5.857-21.213,0  c-5.858,5.857-5.858,15.355,0,21.213L278.787,150H15c-8.284,0-15,6.716-15,15S6.716,180,15,180z"
                    />
                  </svg>
                </button>
              </div>
            </form>
          </div>

          <div
            id="prompEg"
            className="pt-4">
            <p>For Example:</p>
            <div>
              {messageArr.map((value, i) => (
                <p
                  id="fade-in"
                  key={i}
                  style={currentIndex === i ? {} : { display: "none" }}
                  className="animate__animated animate__fadeIn">
                  {value}
                </p>
              ))}
            </div>
          </div>

          <div className="container footer">
            <div className="socials">
              <a
                href="https://instagram.com/caderposter?igshid=YTQwZjQ0NmI0OA%3D%3D&utm_source=qr"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={instagram}
                  alt=""
                />
              </a>
              <a
                href="https://www.tiktok.com/@caderposter?lang=en"
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={tiktok}
                  alt=""
                />
              </a>
            </div>
            <div id="email">
              Email:{" "}
              <a
                href="mailto:cader.help@gmail.com"
                target="_blank"
                rel="noopener noreferrer">
                cader.help@gmail.com
              </a>
            </div>
          </div>
        </div>
      </div>
      <Outlet />
      {/* </div> */}
    </>
  );
};

export default Landing;
