import { useState } from "react";
import { Country, State } from 'country-state-city';
import CustomSelect from "./form-select.component";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setShipping } from "store/cart/cart.action";

const defaultValues = {
    email: '',
    firstname: '',
    lastname: '',
    address: '',
    apartment: '',
    city: '',
    country: '',
    province: '',
    postal_code: '',
    phone: '',
}

const defaultLoc = {
    country: null,
    state: null,
    city: null
}
function InformationForm() {
    const [formData, setFormData] = useState(defaultValues),
        [loc, setLoc] = useState(defaultLoc),
        { email, phone, address, apartment, postal_code, firstname
            , lastname, city } = formData,
        navigate = useNavigate(),
        dispatch = useDispatch(),
        countries = Country.getAllCountries(),
        states = loc.country == null ? null : State.getStatesOfCountry(loc.country.isoCode),
        countriesMap = countries.map(country => ({ ...country, value: country.isoCode, label: country.name })),
        statesMap = states == null ? [] : states.map(state => ({ ...state, value: state.isoCode, label: state.name })),
        handleChange = (e) => {
            const { name, value } = e.target;
            var item;
            switch (name) {
                case 'country':
                    item = countriesMap.find(country => country.isoCode === value);
                    setLoc(state => ({ ...state, [name]: item }));
                    setFormData(state => ({ ...state, [name]: item.name }));
                    break;
                case 'province':
                    item = statesMap.find(state => state.isoCode === value);
                    setLoc(state => ({ ...state, [name]: item }));
                    setFormData(state => ({ ...state, [name]: item.name }));
                    break;
                default:
                    setFormData(state => ({ ...state, [name]: value }));
                    break;
            }
        },
        handleSubmit = async (e) => {
            e.preventDefault();
            dispatch(setShipping(formData));

            navigate('/checkout/shipping')
        }


    return (<form className="container" onSubmit={handleSubmit}>
        <div className="form-group">
            <label htmlFor="exampleInputEmail1">Email address</label>
            <input type="email" className="form-control" id="exampleInputEmail1"
                aria-describedby="emailHelp" placeholder="Enter email"
                onChange={handleChange} value={email} name="email" required />
        </div>
        <div>
            <label >Shipping address</label>
        </div>
        <div className="">
            <div className="form-row form-group">
                <div className="col">
                    <input type="text" className="form-control" placeholder="First name (Optional)" name="firstname" value={firstname}
                        onChange={handleChange} />
                </div>
                <div className="col">
                    <input type="text" className="form-control" placeholder="Last name" name="lastname" value={lastname}
                        onChange={handleChange} required />
                </div>
            </div>

            <div className="form-group">
                <input type="text" className="form-control" placeholder="Address"
                    onChange={handleChange} value={address} name="address" required />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" placeholder="Apartment, Suite etc (optional)"
                    onChange={handleChange} value={apartment} name="apartment" />
            </div>
            <div className="form-group">
                <input type="text" className="form-control" placeholder="City"
                    onChange={handleChange} value={city} name="city" required />
            </div>

            <div className="form-row">
                <div className="col-12 col-sm-4 mb-3 position-relative">
                    <label
                        className="select-input-label"
                    >
                        Country
                    </label>
                    <CustomSelect
                        options={countriesMap}
                        handleChange={({ value }) =>
                            handleChange({
                                target: {
                                    name: "country",
                                    value,
                                },
                            })
                        }
                        required
                    />
                </div>
                <div className="col-12 col-sm-4 mb-3 position-relative">
                    <label
                        className="select-input-label"
                    >
                        Province
                    </label>
                    <CustomSelect
                        options={statesMap}
                        handleChange={({ value }) =>
                            handleChange({
                                target: {
                                    name: "province",
                                    value,
                                },
                            })
                        }
                        required
                    />
                </div>
                <div className="col-12 col-sm-4 mb-3">
                    <input type="text" className="form-control postal_code" placeholder="Postal Code" name="postal_code" value={postal_code}
                        onChange={handleChange} required />
                </div>
            </div>
            <div className="form-group">
                <input type="tel" className="form-control" placeholder="Phone"
                    onChange={handleChange} value={phone} name="phone" required />
            </div>
            <div className="d-flex justify-content-between align-items-center mt-5">
                <button type="button" className="btn" onClick={() => navigate('/display-image')}>Go Back</button>
                <button type="submit" className="btn btn-primary">Continue to Shipping</button>
            </div>
        </div>

    </form>);
}

export default InformationForm;