import Cart from "components/checkout/cart.component";
import InformationForm from "components/checkout/information-form.component";
import { CheckoutHeader } from "components/header";
import { useEffect, } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectCartData } from "store/cart/cart.selector";

function Checkout() {
    const
        searchResult = useSelector(selectCartData),
        navigate = useNavigate()


    useEffect(() => {
        if (searchResult == null) {
            navigate("/");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="row m-0 overflow-hidden w-100" id="checkout">
            <div className="col-md-7 py-5">
                <div className="px-5 ">
                    <CheckoutHeader />

                    <InformationForm searchResult={searchResult} />
                </div>
            </div>
            <div className="col-md-5 checkout-right w-100 pt-5" >
                <div className="px-5 ">
                    <Cart searchResult={searchResult} />
                </div>
            </div>
        </div>);
}

export default Checkout;